export function parseString(inputString) {
    let phone = '';
    let bank = '';
    let name = '';

    const indexBank = inputString.indexOf('Банк:');
    const indexName = inputString.indexOf('Имя:');

    if (indexBank !== -1 && indexName !== -1) {
        phone = inputString.substring(0, indexBank).trim();
        bank = inputString.substring(indexBank + 5, indexName).trim();
        name = inputString.substring(indexName + 4).trim();
    } else {
        // Handle case where markers are not found
        if (indexBank !== -1) {
            phone = inputString.substring(0, indexBank).trim();
            bank = inputString.substring(indexBank + 5).trim();
        } else if (indexName !== -1) {
            phone = inputString.substring(0, indexName).trim();
            name = inputString.substring(indexName + 4).trim();
        } else {
            phone = inputString.trim(); // Fallback to treating entire string as phone number
        }
    }

    return {
        Phone: phone,
        Bank: bank,
        Name: name
    };
}
