
function Done({text, shopId, cardPage = false, backOrderId}) {
    return (
      <>
        <div className={`done__block ${cardPage && "cardPageDone"}`}>
            <div></div>
            <div className="done__t">{text}</div>
            <div className="help__text_ord">Если у вас возникли проблемы с оплатой обратитесь в поддержку ⬇️<br /><br /><span onClick={() => window.open(`https://t.me/CHANGE_SUPPORT_01_BOT?start=${shopId}${backOrderId && `_${backOrderId}`}`)}>НАЖМИТЕ ЗДЕСЬ! </span>⬅️ (Поддержка)</div>
        </div>
      </>
    );
}
  
export default Done;
  