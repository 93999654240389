import { useState, useEffect } from "react";
import Card from "./components/card";
import Loading from "./components/loading";
import toast, { Toaster } from "react-hot-toast";
import appAPI from "./api/service";
import splitNumber from "./helpers/splitNumber";
import { parseString } from "./helpers/parseString";
import Done from "./components/done";

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [pid, setPid] = useState("")
  const [type, setType] = useState(null)
  const [finish, setFinish] = useState(null)

  const [backOrderId, setBackOrderId] = useState(null)

  const [userInfo, setUserInfo] = useState(null);
  const [shopInfo, setShopInfo] = useState(null);
  const [course, setCourse] = useState(null);
  const [orderId, setOrderId] = useState(null)
  const [amount, setAmount] = useState(null);
  const [signature, setSignature] = useState(null);
  const [currency, setCurrency] = useState(null)
  const [toCurrency, setToCurrency] = useState(null)
  const [url, setUrl] = useState("")

  const [chatId, setChatdId] = useState("")
  const [shopId, setShopdId] = useState(null)


  const [apiKey, setApiKey] = useState(null)


  const [amountWithCourse, setAmountWithCourse] = useState(null)
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requizites, setRequizites] = useState(null);
  const [requiziteBack, setRequizitesBack] = useState(null);
  const [requizitesreqPid, setRequizitesreqPid] = useState(null)
  const [requizitesreqTime, setRequizitesreqTime] = useState(null)

  const [redirectUrl, setRedirectUrl] = useState(null)

  const [cancelLoad, setCancleLoad] = useState(false)

  const getPayments = async (token) => {
    const res = await appAPI.getPayments(token);
    if (res?.error) {
      return toast.error(res.error);
    }
    const data = Object.entries(res).map(([key, value]) => ({ key, value }));
    setPaymentMethods(data);
    setPaymentMethod(data[0].value)
  };


  const getCourse = async (currency, toCurrency, token, pid) => {
    const res = await appAPI.getCourse(currency, toCurrency, token, pid);
    if (res?.error) {
      return toast.error(res.error);
    }
    setCourse(res);
  };

  useEffect(() => {
    if (course === null || amount === null) return;
    setAmountWithCourse(parseInt(Number(amount) * Number(course[String(toCurrency).toLowerCase()])));
  }, [amount, course]);

  const getUserInfo = async (id, token) => {
    const res = await appAPI.getUserInfo(id,token);
    if(!res.error) setUserInfo(res)
  };


  const cancelOrder = async (code) => {
    if(cancelLoad == true) return
    setCancleLoad(true)
    const res = await appAPI.cancelOrder({
      "action": "cancelorder",
      "order_id": orderId,
      "type": String(code),
      "pid": pid,
      "api_key": apiKey
    })
    if (res?.error) {
      setCancleLoad(false)
      return toast.error(res.error);
    }
    if(res.status == "Success") {
      setCancleLoad(false)
      setType(code)
      setRequizites(null)
      if(shopInfo.redirect_url_fail) {
        return window.location.href = shopInfo.redirect_url_fail
      }
      window.Telegram.WebApp.close();
      return window.open(shopInfo.url) 
    } else {
      setCancleLoad(false)
      return toast.error("Ошибка при отмене платежа")
    }
    
  }

  const getShopInfo = async (id, token) => {
    const res = await appAPI.getShopInfo(id,token);
    if (res?.error) {
      return toast.error(res.error);
    }
    setShopInfo(res)

    return res.api_key
  };

  useEffect(() => {
    window.Telegram.WebApp.expand()
    const query = new URLSearchParams(window.location.search);

    if(!query.get("id")) return
    async function getInfo() {
      const res = await appAPI.getInfo({
        "action": "getparams",
        "pid": query.get("id")
      })

      setPid(query.get("id"))

      if (res?.error) {
        return toast.error(res.error);
      }
      setType(res.type ? res.type : null)
      setFinish(res.finish ? res.finish : null)
      setBackOrderId(res.id ? res.id : null)
      setAmount(res.amount)
      setSignature("signature")
      setOrderId(res.order_id)

      setRedirectUrl(res.redirect_url && res.redirect_url)
      setUrl(res.url)
      const apiKey = await getShopInfo(res.shop_id)

      getUserInfo(res.chat_id ,apiKey)

      setApiKey(apiKey)
      setChatdId(res.chat_id)
      setShopdId(res.shop_id)

      setCurrency(res.currency.toUpperCase())
      setToCurrency( res.tocurrency ? res.tocurrency.toUpperCase() : res.currency.toUpperCase())

      getCourse(res.currency.toUpperCase(), res.tocurrency ? res.tocurrency.toUpperCase() : res.currency.toUpperCase(), apiKey, query.get("id"))
      getPayments(apiKey)
    }
    getInfo()
  }, []);

  

  const toggleTheme = () => {
    setIsDarkTheme(prevTheme => !prevTheme);
  };

  const handleSubmit = async (retry = false) => {
    if(Number(course["minLimit"]) > amountWithCourse || amountWithCourse > Number(course["maxLimit"])) return toast.error(`Amount exceeds limits: max ${course['maxLimit']} ,min ${course['minLimit']}`)
    if (!paymentMethod) return toast.error("Выберите метод оплаты");
    if(!retry) setLoading(true);
    try {
      const res = await appAPI.getRequizites(paymentMethod, apiKey, orderId, pid)
      if (res?.error) {
        toast.error(res.error);
      } else {
        if(paymentMethod == "СБП") {
          setRequizites(parseString(res.Requisites))
          setRequizitesBack(res.Requisites)
          setRequizitesreqPid(res.reqvPid)
          setRequizitesreqTime(res.dataTime)
        } else {
          setRequizites(res.Requisites)
          setRequizitesBack(res.Requisites)
          setRequizitesreqPid(res.reqvPid)
          setRequizitesreqTime(res.dataTime)
        }
        
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!requizites) return 
    let timer = setInterval(() => {
      handleSubmit(true)
    }, 60000)

    return () => clearInterval(timer)
  }, [requizites])

  const logo = (shopId == 5) ? <img src="/logo1.svg"/> : <svg width="119" height="32" viewBox="0 0 119 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.10084 19.9503C6.36141 19.3819 6.44511 18.6016 6.35195 17.6093C6.35055 17.5931 6.35254 17.5767 6.35781 17.5612C6.36308 17.5458 6.37151 17.5316 6.38256 17.5196C6.39361 17.5076 6.40704 17.4981 6.42199 17.4915C6.43695 17.485 6.4531 17.4817 6.46941 17.4817L9.45641 17.4838C9.52662 17.4838 9.56442 17.5182 9.56982 17.587C9.73182 19.6587 9.36326 22.1475 7.04858 22.8199C5.71 23.2087 3.91375 23.1925 2.65617 22.7854C0.452877 22.0746 0.0438094 19.8612 0.0195083 17.7814C-0.00344265 15.9427 -0.00614276 14.1032 0.011408 12.2631C0.0336839 9.7621 0.651335 7.38464 3.54923 7.0343C7.5265 6.55638 9.69537 7.92129 9.49084 12.099C9.4898 12.1209 9.48038 12.1415 9.46454 12.1566C9.4487 12.1717 9.42766 12.1801 9.40579 12.1801L6.41676 12.176C6.39654 12.1761 6.37654 12.1719 6.358 12.1639C6.33947 12.1558 6.32282 12.1439 6.30911 12.1291C6.2954 12.1142 6.28493 12.0967 6.27836 12.0776C6.27179 12.0585 6.26927 12.0382 6.27095 12.018C6.3236 11.4267 6.2723 10.8347 6.11704 10.242C5.7424 8.80828 3.89755 9.04319 3.49051 10.2886C3.31365 10.834 3.22522 11.4416 3.22522 12.1112C3.22657 13.95 3.234 15.7894 3.2475 17.6296C3.2529 18.3127 3.34065 18.985 3.51076 19.6465C3.83882 20.9163 5.53787 21.1897 6.10084 19.9503Z" fill="white" className="logo1"/>
  <path d="M50.8344 20.4381L50.8364 17.034C50.8364 17.0193 50.8336 17.0049 50.828 16.9913C50.8224 16.9778 50.8142 16.9656 50.8038 16.9552C50.7935 16.9449 50.7812 16.9367 50.7677 16.9311C50.7542 16.9255 50.7397 16.9226 50.7251 16.9226L49.4229 16.9145C49.3716 16.9131 49.346 16.8868 49.346 16.8355L49.348 14.6869C49.348 14.614 49.3851 14.5775 49.4594 14.5775L53.9571 14.5755C53.9877 14.5755 54.0171 14.5877 54.0387 14.6093C54.0604 14.631 54.0725 14.6603 54.0725 14.6909L54.0685 22.4329C54.0678 22.4573 54.0592 22.4809 54.0438 22.4999C54.0284 22.5189 54.0072 22.5324 53.9834 22.5382C52.6739 22.8554 51.3326 23.0512 49.9596 23.1254C46.3124 23.3219 44.5931 22.1696 44.3359 18.4374C44.286 17.7286 44.259 15.9911 44.2549 13.2248C44.2509 10.4443 44.7004 7.70237 47.9345 7.11307C51.606 6.44479 54.3884 7.85628 54.0462 11.9895C54.0447 12.011 54.035 12.0311 54.0192 12.0457C54.0035 12.0604 53.9827 12.0685 53.9612 12.0685L50.9782 12.0624C50.9603 12.0624 50.9426 12.0588 50.9261 12.0516C50.9097 12.0445 50.895 12.034 50.8828 12.0209C50.8706 12.0078 50.8613 11.9923 50.8554 11.9754C50.8495 11.9585 50.8472 11.9405 50.8486 11.9227C50.9296 10.6509 50.6582 9.09158 48.947 9.35282C47.6125 9.55735 47.5315 10.9992 47.5113 12.0604C47.4687 14.4398 47.3189 16.9975 47.6449 19.3527C47.8798 21.0396 49.4837 20.9323 50.7433 20.5617C50.804 20.5428 50.8344 20.5016 50.8344 20.4381Z" fill="white"className="logo1" />
  <path d="M25.0408 19.5859L24.4434 22.7633C24.4382 22.7895 24.4241 22.8132 24.4034 22.8302C24.3827 22.8472 24.3567 22.8564 24.33 22.8564L21.4523 22.8584C21.435 22.8583 21.4179 22.8542 21.4024 22.8466C21.3869 22.839 21.3733 22.8279 21.3626 22.8143C21.3519 22.8007 21.3444 22.7849 21.3406 22.768C21.3369 22.7511 21.337 22.7335 21.3409 22.7167L24.8545 7.24299C24.8604 7.21781 24.8745 7.19533 24.8947 7.17917C24.9149 7.16302 24.94 7.15411 24.9658 7.15389L28.5685 7.15186C28.5947 7.15163 28.6202 7.16033 28.6408 7.17652C28.6614 7.19272 28.6759 7.21544 28.6819 7.24096L32.1873 22.7207C32.1913 22.7378 32.1914 22.7555 32.1875 22.7725C32.1836 22.7896 32.176 22.8055 32.1651 22.8192C32.1541 22.8328 32.1402 22.8438 32.1245 22.8513C32.1087 22.8588 32.0914 22.8626 32.0739 22.8625L29.1942 22.8544C29.1672 22.8543 29.141 22.8448 29.1203 22.8274C29.0995 22.81 29.0856 22.7858 29.0808 22.7592L28.5077 19.5899C28.503 19.5633 28.489 19.5392 28.4683 19.5218C28.4476 19.5044 28.4214 19.4948 28.3943 19.4948L25.1542 19.4927C25.1274 19.4927 25.1014 19.502 25.0808 19.519C25.0601 19.536 25.0459 19.5596 25.0408 19.5859ZM26.8411 10.3393C26.8127 10.18 26.7837 10.18 26.754 10.3393L25.5025 17.0748C25.5007 17.083 25.5008 17.0915 25.5028 17.0996C25.5048 17.1078 25.5087 17.1155 25.5141 17.1221C25.5195 17.1286 25.5264 17.134 25.5342 17.1377C25.542 17.1415 25.5505 17.1435 25.5592 17.1436H27.9407C28.0257 17.1436 28.0608 17.1018 28.046 17.0181L26.8411 10.3393Z" fill="white" className="logo1"/>
  <path d="M36.1377 14.0939L36.1437 22.6984C36.1437 22.8064 36.0897 22.8605 35.9817 22.8605L33.102 22.8665C33.0693 22.8665 33.0379 22.8535 33.0147 22.8303C32.9915 22.8072 32.9785 22.7758 32.9785 22.743L32.9947 7.25716C32.9947 7.22977 33.0058 7.2035 33.0256 7.18413C33.0453 7.16476 33.0721 7.15388 33.1 7.15388L36.0323 7.15186C36.0623 7.15183 36.0916 7.16088 36.1165 7.17784C36.1414 7.19481 36.1608 7.2189 36.1721 7.24703L39.7545 16.0805C39.8152 16.233 39.8456 16.2269 39.8456 16.0622L39.8476 7.29766C39.8476 7.20181 39.8955 7.15388 39.9914 7.15388L42.8913 7.15186C42.923 7.15186 42.9534 7.16423 42.9758 7.18626C42.9982 7.20828 43.0108 7.23816 43.0108 7.26931L43.0128 22.7065C43.0128 22.7476 42.9967 22.7869 42.9679 22.8161C42.9391 22.8453 42.8999 22.8619 42.8589 22.8625L39.9408 22.8686C39.8804 22.8686 39.8212 22.8503 39.7711 22.816C39.7209 22.7817 39.6819 22.733 39.6593 22.6762L36.2207 14.0777C36.164 13.9359 36.1363 13.9413 36.1377 14.0939Z" fill="white" className="logo1"/>
  <path d="M58.7027 16.1189L58.7087 20.4142C58.7087 20.4421 58.7198 20.4689 58.7396 20.4886C58.7593 20.5084 58.7861 20.5195 58.814 20.5195L63.3219 20.5134C63.3498 20.5134 63.3766 20.5245 63.3964 20.5442C63.4161 20.564 63.4272 20.5908 63.4272 20.6187V22.7511C63.4272 22.779 63.4161 22.8058 63.3964 22.8256C63.3766 22.8453 63.3498 22.8564 63.3219 22.8564L55.5861 22.8584C55.5581 22.8584 55.5313 22.8473 55.5116 22.8276C55.4918 22.8078 55.4808 22.7811 55.4808 22.7531L55.4727 7.25716C55.4727 7.22923 55.4838 7.20245 55.5035 7.1827C55.5232 7.16295 55.55 7.15186 55.578 7.15186H63.2368C63.2648 7.15186 63.2916 7.16295 63.3113 7.1827C63.3311 7.20245 63.3421 7.22923 63.3421 7.25716L63.3401 9.38755C63.3401 9.41548 63.329 9.44226 63.3093 9.46201C63.2895 9.48176 63.2627 9.49286 63.2348 9.49286L58.808 9.49488C58.78 9.49488 58.7533 9.50598 58.7335 9.52572C58.7138 9.54547 58.7027 9.57226 58.7027 9.60019L58.7047 13.543C58.7047 13.5569 58.7074 13.5706 58.7127 13.5833C58.718 13.5961 58.7258 13.6077 58.7355 13.6175C58.7453 13.6273 58.7569 13.635 58.7697 13.6403C58.7825 13.6456 58.7962 13.6483 58.81 13.6483L63.0728 13.6605C63.1007 13.6605 63.1275 13.6716 63.1473 13.6913C63.167 13.7111 63.1781 13.7379 63.1781 13.7658L63.1761 15.9063C63.1761 15.9342 63.165 15.961 63.1452 15.9808C63.1255 16.0005 63.0987 16.0116 63.0708 16.0116L58.808 16.0136C58.78 16.0136 58.7533 16.0247 58.7335 16.0445C58.7138 16.0642 58.7027 16.091 58.7027 16.1189Z" fill="white" className="logo1"/>
  <path d="M13.9359 16.1694L13.9399 22.8117C13.9399 22.8391 13.929 22.8653 13.9097 22.8847C13.8903 22.9041 13.864 22.9149 13.8366 22.9149H10.8152C10.7878 22.9149 10.7615 22.9041 10.7422 22.8847C10.7228 22.8653 10.7119 22.8391 10.7119 22.8117V7.30355C10.7119 7.28998 10.7146 7.27655 10.7198 7.26402C10.725 7.25149 10.7326 7.24011 10.7422 7.23052C10.7518 7.22093 10.7631 7.21332 10.7757 7.20813C10.7882 7.20294 10.8016 7.20027 10.8152 7.20027H13.8366C13.864 7.20027 13.8903 7.21115 13.9097 7.23052C13.929 7.24989 13.9399 7.27616 13.9399 7.30355L13.9379 13.6157C13.9379 13.6431 13.9488 13.6694 13.9681 13.6888C13.9875 13.7081 14.0138 13.719 14.0412 13.719L16.8925 13.717C16.9199 13.717 16.9461 13.7061 16.9655 13.6867C16.9849 13.6674 16.9958 13.6411 16.9958 13.6137V7.30557C16.9958 7.27818 17.0066 7.25191 17.026 7.23254C17.0454 7.21317 17.0716 7.20229 17.099 7.20229L20.1205 7.19824C20.1479 7.19824 20.1741 7.20912 20.1935 7.22849C20.2129 7.24786 20.2237 7.27413 20.2237 7.30152V22.8117C20.2237 22.8391 20.2129 22.8653 20.1935 22.8847C20.1741 22.9041 20.1479 22.9149 20.1205 22.9149L17.099 22.917C17.0716 22.917 17.0454 22.9061 17.026 22.8867C17.0066 22.8673 16.9958 22.8411 16.9958 22.8137V16.1694C16.9958 16.142 16.9849 16.1157 16.9655 16.0963C16.9461 16.077 16.9199 16.0661 16.8925 16.0661H14.0391C14.0117 16.0661 13.9855 16.077 13.9661 16.0963C13.9467 16.1157 13.9359 16.142 13.9359 16.1694Z" fill="white" className="logo1"/>
  <path d="M65.2539 15.2597V29.5016H69.5243V15.2597C69.5243 12.903 71.5325 11.0148 73.8626 11.0148C74.9412 11.0148 76.0907 11.4504 76.874 12.1946L76.8761 12.1966L76.8783 12.1986C77.8567 13.0838 78.3215 14.4287 78.1713 15.7306C78.0533 16.753 77.5779 17.709 76.7904 18.3784L76.7881 18.3803L76.7859 18.3823C75.4732 19.5568 73.6357 19.7165 72.0221 19.0959C71.4779 20.3822 70.9285 21.6638 70.4865 22.9898C71.5691 23.4274 72.6902 23.6817 73.8626 23.6817C76.1353 23.6817 78.39 22.7996 80.0043 21.186C81.5834 19.649 82.5024 17.4623 82.5024 15.2597C82.5024 13.0098 81.5862 10.8412 79.9732 9.27128C78.3705 7.66926 76.1166 6.80668 73.8626 6.80668C71.5739 6.80668 69.3635 7.70411 67.722 9.30128L67.7197 9.30357C66.134 10.8892 65.2539 13.0175 65.2539 15.2597Z" fill="#FF0000"/>
  <path d="M89.3909 23.0074L89.3936 23.0085L89.3962 23.0096C91.6155 23.9417 94.0681 23.8416 96.2894 22.9803C95.7171 21.8356 95.3167 20.6136 94.8132 19.4386C93.6347 19.8918 92.2499 19.9944 91.088 19.4364C90.0419 18.8932 89.2515 18.0074 88.8065 16.9181C88.4037 15.7868 88.409 14.6614 88.8368 13.5723C89.2641 12.4848 90.0366 11.5777 91.0877 11.0521L91.0908 11.0505C92.1478 10.5024 93.4777 10.5142 94.5581 10.9944L94.5615 10.9959L94.5649 10.9973C96.2772 11.695 97.1563 13.4882 97.1563 15.2597V23.2771H101.458V15.2597C101.458 14.0876 101.234 12.9866 100.784 11.9592C99.9015 9.94123 98.2488 8.31195 96.2084 7.47859C94.0947 6.57337 91.5712 6.57361 89.458 7.47893C87.4391 8.32044 85.7614 9.91987 84.8828 11.9281C83.9825 13.9859 83.988 16.4255 84.85 18.4942L84.8511 18.4969L84.8524 18.4997C85.7532 20.5158 87.3808 22.1048 89.3909 23.0074Z" fill="#FF0000"/>
  <path d="M107.765 16.1V7.24234H103.463V16.1C103.463 20.4471 107.067 23.6396 111.383 23.7127L111.387 23.7128L111.391 23.7127C112.315 23.6962 113.154 23.5768 113.908 23.3515C113.587 23.8122 113.161 24.2243 112.624 24.587C111.327 25.4633 109.669 25.4907 108.227 24.995L108.225 24.9943L108.223 24.9936C107.424 24.7358 106.731 24.2623 106.071 23.757C105.224 25.0265 104.33 26.2618 103.405 27.4754C106.588 29.1731 110.582 30.423 114.042 28.7624C115.105 28.2518 116.003 27.5797 116.731 26.7452C118.17 25.1406 119 22.988 119 20.8306V7.24234H114.698V16.1C114.698 17.3661 113.937 18.5153 112.833 19.0961L112.831 19.0974C111.93 19.5971 110.805 19.6296 109.858 19.2421C108.593 18.7243 107.765 17.4721 107.765 16.1Z" fill="#FF0000"/>
  </svg>

  if(shopId === null) return 
  if (course === null || amount === null || shopInfo === null || amountWithCourse == null || paymentMethods == null || currency == null) return <div className="start__load">{logo}</div>;
  if(!shopInfo.name || shopInfo.name === "TypeError" || !apiKey) return <Done text={"Пересоздайте ссылку на платеж"}/>
  return (
    <>
      <div className={`app ${isDarkTheme ? 'dark' : 'light'}`}>
        {type !== null && (finish && finish == 1 && type == 1) ? <Done backOrderId={backOrderId} shopId={shopId} text={"Ваш заказ оплачен"}/> : type == 1 ? <Done backOrderId={backOrderId} shopId={shopId} text={"Заказ обрабатывается, ожидайте"}/> : type == -1 ? <Done backOrderId={backOrderId} shopId={shopId} text={"Заказ отклонен"}/> : type == -2 ? <Done backOrderId={backOrderId} shopId={shopId} text={"Отмена по истeчению времени"}/> :   <>
        <div className="container">
          <div className="header">
          {logo}
            <select className="select__header">
              <option>Русский</option>
            </select>
          </div>
          {isDarkTheme ? <img src="/sun.svg" className="image__theme" onClick={toggleTheme} /> : <img src="/moon.svg" onClick={toggleTheme} className="image__theme" />}
          {loading !== true && !requizites ?
            <>
              <div className="sum__text">Сумма</div>
              <div className="sum__value">{splitNumber(amountWithCourse)} {String(toCurrency).toLowerCase() === "rub" ? "₽" : String(toCurrency).toLowerCase() === "usd" || String(toCurrency).toLowerCase() === "usdt" ? "$" : String(toCurrency).toLowerCase() }</div>
              <div className="row__data">
                <div className="row__data__key">Магазин</div>
                <div className="row__data__value" style={{fontSize: String(shopInfo.name).length > 15 ? "15px" : "20px" }}>{shopInfo.name}</div>
              </div>
              <div className="row__data">
                <div className="row__data__key">Заказ</div>
                <div className="row__data__value " style={{fontSize: String(orderId).length > 15 ? "11px" : "14px" }}>{orderId}</div>
              </div>
              <div className="choose__payment">
                Выберите платежную систему
              </div>
              <select className="select" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                {paymentMethods.map(method => (
                  <option key={method.key} value={method.value}>{method.value}</option>
                ))}
              </select>
              <div className="button" onClick={() => handleSubmit()}>
                Подтвердить
              </div>
              {/*<div className="text">Пожалуйста, выберите из списка банков</div>*/}
              <div className="cancel" onClick={() => {
                cancelOrder(-1)
              }}>Отменить платеж</div><div className="help__text_ord">Если у вас возникли проблемы с оплатой обратитесь в поддержку ⬇️<br /><br /><span onClick={() => window.open(`https://t.me/CHANGE_SUPPORT_01_BOT?start=${shopId}${backOrderId && `_${backOrderId}`}`)}>НАЖМИТЕ ЗДЕСЬ! </span>⬅️ (Поддержка)</div>
            </> : loading === true && !requizites ? <Loading /> : loading === false && requizites ? <Card requiziteBack={requiziteBack} requizitesreqTime={Number(requizitesreqTime)} outTime={shopInfo.out_time} cancelOrder={cancelOrder} backOrderId={backOrderId} apiKey={apiKey} pid={pid} redirectUrl={redirectUrl} toCurrency={toCurrency} setRequizites={setRequizites} currency={currency} course={course} requizitesreqPid={requizitesreqPid} requizites={requizites} shopInfo={shopInfo} chatId={chatId} shopId={shopId} amountWithCourse={amountWithCourse} amount={amount} paymentMethod={paymentMethod} orderId={orderId}/> : ""}
          </div>
        </> }
        </div>
    </>
  );
}

export default App;
