
function Loading() {
    return (
      <>
        <div className="loader__block">
            <div className="loader">
                <img src="/loader.svg" className="load"/>
                <div className="loader__t">Идет обработка</div>
                <div className="loader__v">Пожалуйста ожидайте...</div>
            </div>
        </div>
      </>
    );
  }
  
  export default Loading;
  